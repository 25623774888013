<template>
  <v-container fluid>
    <!-- First Row -->
    <v-row>
      <!-- First Column in the First Row -->
      <v-col
        cols="12"
        md="6"
        v-if="
          ((videos && videos.length > 0) || (images && images.length > 0)) &&
          verificationDetails.deleted_at == null
        "
      >
        <!-- Video Section -->
        <div v-if="videos && videos.length > 0">
          <p class="title-text ml-5 pb-2">Video Proof</p>
          <v-row v-if="firstLoad" class="py-5 mt-4">
            <v-col v-for="index in 1" :key="index" cols="12" md="4">
              <v-skeleton-loader
                type="list-item-two-line"
                :loading="true"
                elevation="0"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-col v-else class="video-container text-center">
            <video class="card-video mb-5 rounded" controls muted>
              <source :src="videos[0].url" type="video/mp4" />
            </video>
            <p
              v-if="service === 'liveness'"
              class="font-10 secondary--text fw-400"
            >
              Detailed Liveness Video Proof from
              {{ browserHistory.platform }} at
              {{ videos[0].created_at | convertUTCtoLocalTime }}
            </p>
          </v-col>
        </div>

        <!-- Image Section -->
        <div v-if="images.length > 0">
          <h5 class="title-text ml-5 pb-2">Image Proof</h5>
          <v-row v-if="firstLoad" class="py-5 mt-4">
            <v-col v-for="index in 1" :key="index" cols="12" md="4">
              <v-skeleton-loader
                type="list-item-two-line"
                :loading="true"
                elevation="0"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col
              cols="12"
              v-if="
                !$vuetify.breakpoint.smAndDown &&
                (face_match.length > 0 || images.length > 1)
              "
              class="image-text ma-0 pa-0"
            >
              <p class="font-12 secondary--text fw-400 mb-0 mt-5">
                Original Image
              </p>
              <p class="font-12 secondary--text fw-400 mb-0 mt-5">
                Matched Image
              </p>
            </v-col>
            <v-col
              class="image-container d-flex flex-row ma-0 pa-0"
              :cols="$vuetify.breakpoint.smAndDown ? 6 : 2"
            >
              <div v-for="(img, index) in images" :key="index">
                <img
                  class="rounded live-img cursor-pointer image-resize-set"
                  @click="openImageModal(null, img.url)"
                  :src="img.thumbnail_url"
                />
              </div>
              <div v-if="face_match.length > 0">
                <v-col
                  class="image-container ma-0 pa-0"
                  :cols="$vuetify.breakpoint.smAndDown ? 6 : 2"
                >
                  <div>
                    <img
                      :id="'main-face-0'"
                      class="rounded live-img cursor-pointer image-resize-set"
                      :src="
                        selected_face !== '' ? selected_face : face_match[0].url
                      "
                      @click="openImageModal(face_match, null)"
                    />
                  </div>
                </v-col>
              </div>
            </v-col>
            <v-col v-if="face_match.length > 0" cols="ma-0 pa-0">
              <span class="image-container font-10 secondary--text fw-400 mb-2">
                Matched Face(s)
              </span>
              <v-col
                class="image-container d-flex ma-0 pa-0"
                :cols="$vuetify.breakpoint.smAndDown ? 6 : 2"
              >
                <div
                  class="image-contents pointer"
                  v-for="(img, index) in face_match"
                  :key="index"
                >
                  <img
                    @click="selected_face_upper(img.url, index)"
                    :id="'thumbnail-face-' + index"
                    class="rounded"
                    :src="img.url"
                    alt=""
                    style="width: 40px; height: 40px"
                  />
                </div>
              </v-col>
            </v-col>
          </v-row>
          <div
            v-if="service == 'photo_id_match'"
            class="d-flex justify-center mx-auto mt-3"
          >
            <div class="set-progress-linear-width">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-progress-linear
                    v-bind="attrs"
                    v-on="on"
                    :color="getScoreColor"
                    height="15"
                    rounded
                    :value="similarityScore"
                  >
                    <small
                      class="similarity-score"
                      :class="getSimilarityTextColor"
                      >{{ similarityScore }}%</small
                    ></v-progress-linear
                  >
                </template>
                <span class="tooltip-text-style">Face Similarity Score</span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </v-col>
      <v-col
        v-else-if="verificationDetails.deleted_at"
        cols="12"
        md="6"
        sm="12"
        xs="12"
        lg="6"
      >
        <v-row v-if="firstLoad" class="py-2 mt-2">
          <v-col v-for="index in 3" :key="index" cols="12" md="4">
            <v-skeleton-loader
              type="list-item-two-line"
              :loading="true"
              elevation="0"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
        <div v-else>
          <p class="deleted-transaction ml-5 pb-2">Transaction Data Deleted</p>
          <v-row class="browser-history pt-1">
            <!-- deleted at -->
            <v-col cols="4" class="pr-0 pb-0">
              <span class="browser-content">Deleted On :</span>
            </v-col>
            <v-col cols="8" class="pl-0 pb-0 d-flex flex-column">
              <span class="show-time-created">{{
                formatTransactionTime(verificationDetails.deleted_at)
              }}</span>
              <span class="show-day-created">{{
                formatTransactionDay(verificationDetails.deleted_at)
              }}</span>
            </v-col>
            <!-- comment -->
            <v-col cols="4" class="pr-0 pb-0">
              <span class="browser-content">Deleted Reason :</span>
            </v-col>
            <v-col cols="8" class="pl-0 pb-0">
              <span class="sub-title-text">{{
                verificationDetails.delete_comment
              }}</span>
            </v-col>
          </v-row>
        </div>
      </v-col>

      <!-- Divider between columns in the first row -->
      <span v-if="showDivider" class="divider-col">
        <v-divider vertical></v-divider>
      </span>

      <v-row class="ma-4 d-md-none">
        <v-divider class=""></v-divider>
      </v-row>

      <!-- Second Column in the First Row -->
      <v-col cols="12" md="6">
        <p class="title-text ml-5 pb-2">Verification Details</p>
        <v-row v-if="firstLoad" class="py-2 mt-2">
          <v-col v-for="index in 3" :key="index" cols="12" md="4">
            <v-skeleton-loader
              type="list-item-two-line"
              :loading="true"
              elevation="0"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row v-else class="browser-history pt-1">
          <!-- service -->
          <v-col cols="4" class="pr-0 pb-0">
            <span class="browser-content">{{ header("Service") }}:</span>
          </v-col>
          <v-col cols="8" class="pl-0 pb-0">
            <span class="sub-title-text">{{
              service == "document_ocr"
                ? "Document OCR"
                : formatService(service)
            }}</span>
          </v-col>
          <!-- status -->
          <v-col cols="4" class="pr-0 pb-0">
            <span class="browser-content">{{ header("Status") }}:</span>
          </v-col>
          <v-col cols="8" class="pl-0 pb-0">
            <v-chip
              class="custom-chip"
              :class="getStatusClass(verificationDetails)"
            >
              {{ verificationDetails.status_display_value }}
            </v-chip>
          </v-col>
          <!-- Liveness Type -->
          <v-col v-if="service === 'liveness'" cols="4" class="pr-0 pb-0">
            <span class="browser-content">{{ header("Liveness Type") }}:</span>
          </v-col>
          <v-col v-if="service === 'liveness'" cols="8" class="pl-0 pb-0">
            <span class="sub-title-text">
              {{
                verificationDetails.detail_liveness_exist ? "Detail" : "Quick"
              }}
            </span>
          </v-col>
          <!-- decline_reason -->
          <v-col
            v-if="
              verificationDetails.status != 'ACCEPTED' &&
              service !== 'document_ocr'
            "
            cols="4"
            class="pr-0 pb-0"
          >
            <span class="browser-content">{{ header("Decline Reason") }}:</span>
          </v-col>
          <v-col
            v-if="
              verificationDetails.status != 'ACCEPTED' &&
              service !== 'document_ocr'
            "
            cols="8"
            class="pl-0 pb-0"
          >
            <span class="sub-title-text">{{
              verificationDetails.decline_reason !== null
                ? verificationDetails.decline_reason
                : "N/A"
            }}</span>
          </v-col>
          <!-- reference_no -->
          <v-col cols="4" class="pr-0 pb-0">
            <span class="browser-content">{{ header("Reference No") }}:</span>
          </v-col>
          <v-col cols="8" class="pl-0 pb-0">
            <span class="sub-title-text">{{
              verificationDetails.reference_no
            }}</span>
          </v-col>
          <!-- date -->
          <v-col v-if="service !== 'document_ocr'" cols="4" class="pr-0 pb-0">
            <span class="browser-content">{{ header("Date") }}:</span>
          </v-col>
          <v-col v-if="service !== 'document_ocr'" cols="8" class="pl-0 pb-0">
            <span class="sub-title-text">{{
              verificationDetails.date !== null
                ? verificationDetails.date
                : "N/A"
            }}</span>
          </v-col>
          <!-- created_time -->
          <v-col cols="4" class="pr-0 pb-0">
            <span class="browser-content">{{ header("Created At") }}:</span>
          </v-col>
          <v-col cols="8" class="pl-0 pb-0">
            <span class="sub-title-text">{{
              verificationDetails.created_time | timeConversionClientTimeZone
            }}</span>
          </v-col>
          <!-- result_time -->
          <v-col v-if="service !== 'document_ocr'" cols="4" class="pr-0 pb-0">
            <span class="browser-content">{{ header("Result Time") }}:</span>
          </v-col>
          <v-col v-if="service !== 'document_ocr'" cols="8" class="pl-0 pb-0">
            <span class="sub-title-text">{{
              verificationDetails.result_time | timeConversionClientTimeZone
            }}</span>
          </v-col>

          <!-- OCR Json -->
          <v-col v-if="service == 'document_ocr'" cols="4" class="pr-0 pb-0">
            <span class="browser-content">{{ header("OCR Result") }}:</span>
          </v-col>
          <v-col v-if="service == 'document_ocr'" cols="8" class="pl-0 pb-0">
            <v-card class="ocr-result-card mb-5">
              <json-tree :data="documentOCR"></json-tree>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Divider between rows -->
    <v-row class="divider-row">
      <v-divider class="mx-4"></v-divider>
    </v-row>
    <v-row class="ma-4 d-md-none">
      <v-divider class=""></v-divider>
    </v-row>

    <!-- Second Row -->
    <v-row>
      <!-- First Column in the Second Row -->
      <v-col cols="12" md="6" class="pb-4">
        <p
          class="title-text ml-5 pb-2"
          v-if="Object.keys(browserHistory).length !== 0"
        >
          Browser Details
        </p>
        <v-row v-if="firstLoad" class="py-5">
          <v-col v-for="index in 2" :key="index" cols="12" md="4">
            <v-skeleton-loader
              type="list-item-three-line"
              :loading="true"
              elevation="0"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row
          v-else
          class="browser-history pt-1"
          v-for="(history, i) in browserHistory"
          :key="i"
        >
          <v-col cols="4" class="pr-0 pb-0">
            <span class="browser-content">
              {{ header(i.replace("_", " ")) }}:
            </span>
          </v-col>
          <v-col cols="8" class="pl-0 pb-0">
            <span class="sub-title-text">{{ history }}</span>
          </v-col>
        </v-row>
      </v-col>

      <!-- Divider between columns in the second row -->
      <span v-if="showDivider" class="divider-col">
        <v-divider vertical></v-divider>
      </span>
      <v-row class="ma-4 d-md-none">
        <v-divider class=""></v-divider>
      </v-row>
      <!-- Second Column in the Second Row -->
      <v-col cols="12" md="6" style="padding-bottom: 50px">
        <p
          class="title-text ml-5 pb-2"
          v-if="Object.keys(geoGraphicalDetails).length !== 0"
        >
          Geographical Details
        </p>
        <v-row v-if="firstLoad" class="py-5">
          <v-col v-for="index in 2" :key="index" cols="12" md="4">
            <v-skeleton-loader
              type="list-item-three-line"
              :loading="true"
              elevation="0"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row v-else class="browser-history pt-1">
          <!-- Host -->
          <v-col cols="4" class="pr-0 pb-0">
            <span class="browser-content">{{ header("Host") }}:</span>
          </v-col>
          <v-col cols="8" class="pl-0 pb-0">
            <span class="sub-title-text">{{ host }}</span>
          </v-col>

          <!-- ISP -->
          <v-col cols="4" class="pr-0 pb-0">
            <span class="browser-content">{{ header("ISP") }}:</span>
          </v-col>
          <v-col cols="8" class="pl-0 pb-0">
            <span class="sub-title-text">{{ isp }}</span>
          </v-col>
          <!-- Continent -->
          <v-col cols="4" class="pr-0 pb-0">
            <span class="browser-content">{{ header("Continent") }}:</span>
          </v-col>
          <v-col cols="8" class="pl-0 pb-0">
            <span class="sub-title-text">{{ continent }}</span>
          </v-col>

          <!-- Continent Code -->
          <v-col cols="4" class="pr-0 pb-0">
            <span class="browser-content">{{ header("Continent Code") }}:</span>
          </v-col>
          <v-col cols="8" class="pl-0 pb-0">
            <span class="sub-title-text">{{ continent_code }}</span>
          </v-col>

          <!-- Country Code -->
          <v-col cols="4" class="pr-0 pb-0">
            <span class="browser-content">{{ header("Country Code") }}:</span>
          </v-col>
          <v-col cols="8" class="pl-0 pb-0">
            <span class="sub-title-text">{{ country_code }}</span>
          </v-col>

          <!-- Country Name -->
          <v-col cols="4" class="pr-0 pb-0">
            <span class="browser-content">{{ header("Country Name") }}:</span>
          </v-col>
          <v-col cols="8" class="pl-0 pb-0">
            <span class="sub-title-text">{{ country_name }}</span>
          </v-col>
          <!-- Latitude -->
          <v-col cols="4" class="pr-0 pb-0">
            <span class="browser-content">{{ header("Latitude") }}:</span>
          </v-col>
          <v-col cols="8" class="pl-0 pb-0">
            <span class="sub-title-text">{{ latitude }}</span>
          </v-col>

          <!-- Longitude -->
          <v-col cols="4" class="pr-0 pb-0">
            <span class="browser-content">{{ header("Longitude") }}:</span>
          </v-col>
          <v-col cols="8" class="pl-0 pb-0">
            <span class="sub-title-text">{{ longitude }}</span>
          </v-col>

          <!-- RDNS -->
          <v-col cols="4" class="pr-0 pb-0">
            <span class="browser-content">{{ header("RDNS") }}:</span>
          </v-col>
          <v-col cols="8" class="pl-0 pb-0">
            <span class="sub-title-text">{{ rdns }}</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- vue-light-box-component -->
    <vue-easy-lightbox
      escDisabled
      moveDisabled
      :visible="visible"
      :imgs="modalImageArray"
      :index="index"
      @hide="handleHide"
    ></vue-easy-lightbox>
  </v-container>
</template>

<script>
import ApiService from "@/services/Api";
import VueEasyLightbox from "vue-easy-lightbox";
import { showErrorMessage } from "@/utils/showError";
import JsonTree from "vue-json-tree";
import moment from "moment";
import { API_URL, ADMIN_API_URL } from "@/utils/env.js";
export default {
  components: { VueEasyLightbox, JsonTree },
  data() {
    return {
      visible: false,
      index: 0,
      showDivider: true,
      details: {},
      verificationDetails: {},
      browserHistory: {},
      geoGraphicalDetails: {},
      documentOCR: {},
      firstLoad: false,
      requestDetails: {},
      service: "",
      videos: [],
      baseUrl: API_URL,
      proofBaseUrl: ADMIN_API_URL,
      images: [],
      continent: "",
      continent_code: "",
      country_code: "",
      country_name: "",
      host: "",
      isp: "",
      latitude: "",
      longitude: "",
      rdns: "",
      face_match: [],
      selected_face: "",
      modalImageUrl: "",
      modalImageArray: [],
      selected_face_index: "",
      similarityScore: 0,
    };
  },
  computed: {
    getScoreColor() {
      return this.similarityScore < 75 && this.similarityScore >= 50
        ? "#FDD835"
        : this.similarityScore < 50
        ? "#E53935"
        : "#388E3C";
    },
    getSimilarityTextColor() {
      return this.similarityScore < 75
        ? "similarity-score-black"
        : "similarity-score-white";
    },
  },
  methods: {
    /**
     * Sets the visibility of the component to true.
     */
    show() {
      this.visible = true;
    },

    /**
     * Sets the visibility of the component to false.
     */
    handleHide() {
      this.visible = false;
    },

    /**
     * Returns an object with CSS classes based on the item's status.
     * @param {Object} item - The item containing status information.
     * @returns {Object} - An object with CSS classes based on the item's status.
     */
    getStatusClass(item) {
      return {
        "custom-age":
          item.status === "ACCEPTED" && item.service === "age_estimation",
        "status-pending": item.status === "PENDING",
        "status-declined": item.status === "DECLINED",
        "status-in-process": item.status === "INPROCESS",
        "status-initiated": item.status === "INITIATED",
        "status-timeouts": item.status === "TIMEOUT",
        "status-not-found": item.status === "NOT_FOUND",
        "status-accepted": item.status === "ACCEPTED",
      };
    },

    /**
     * Opens the image modal with the specified image array or string.
     * @param {Array} imgArray - An array of image URLs.
     * @param {string} imgString - A single image URL.
     */
    openImageModal(imgArray, imgString) {
      if (imgArray) {
        this.modalImageArray = [];
        imgArray.forEach((i) => {
          this.modalImageArray.push(i.url);
        });
      } else {
        this.modalImageArray = [];
        this.modalImageArray.push(imgString);
      }
      this.show();
    },

    /**
     * Sets the selected face and its index.
     * @param {Object} face - The selected face object.
     * @param {number} index - The index of the selected face.
     */
    selected_face_upper(face, index) {
      this.selected_face = face;
      this.selected_face_index = index;
    },

    /**
     * Capitalizes the first letter of each word in a string.
     * @param {string} i - The input string.
     * @returns {string} - The capitalized string.
     */
    header(i) {
      const words = i.split(" ");
      return words
        .map((word) => {
          return word[0].toUpperCase() + word.substring(1);
        })
        .join(" ");
    },

    /**
     * Formats a service string by removing underscores and capitalizing the first letter of each word.
     * @param {string} str - The input string to be formatted.
     * @returns {string} - The formatted string.
     */
    formatService(str) {
      // Remove underscores and capitalize first letter
      return str.replace(/_/g, " ").replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },

    /**
     * Formats transaction time to display how long ago it occurred.
     * @param {Date} transactionTime - The transaction time as a Date object.
     * @returns {string} - The formatted transaction time.
     */
    formatTransactionTime(transactionTime) {
      const currentTime = moment();
      const transactionMoment = moment(transactionTime);

      const duration = moment.duration(currentTime.diff(transactionMoment));
      const minutesAgo = duration.asMinutes();
      const hoursAgo = duration.asHours();

      if (minutesAgo < 60) {
        return `${Math.floor(minutesAgo)} Mins ago`;
      } else if (hoursAgo < 24) {
        return `${Math.floor(hoursAgo)} hours ago`;
      } else {
        return transactionMoment.format("HH:mm:ss A");
      }
    },

    /**
     * Formats a transaction day to display 'Today', 'Yesterday', or the date in the format 'DD / MMM / YYYY'.
     * @param {Date|string} actualTime - The transaction time as a Date object or valid date string.
     * @returns {string} - The formatted transaction day.
     */
    formatTransactionDay(actualTime) {
      // Assuming actualTime is a valid date string or Date object
      const today = moment().startOf("day");
      const createdDate = moment(actualTime);

      // Check if the date is today
      if (createdDate.isSame(today, "day")) {
        return "Today";
      }

      // Check if the date is yesterday
      const yesterday = moment().subtract(1, "days").startOf("day");
      if (createdDate.isSame(yesterday, "day")) {
        return "Yesterday";
      }

      // For older dates, format as 'DD / MMM / YYYY'
      const formattedDate = createdDate.format("DD / MMM / YYYY");
      return formattedDate;
    },

    /**
     * Retrieves transaction details from the server and updates the component's data accordingly.
     */
    getTransactionDetail() {
      this.firstLoad = true;
      ApiService.GetRequest(
        `/merchant/transaction-details/${this.$route.params.id}`
      )
        .then((response) => {
          const data = response.data.result.data;
          this.firstLoad = false;
          this.browserHistory = data.browser_details
            ? data.browser_details
            : {};
          this.verificationDetails = data.verification_details
            ? data.verification_details
            : {};
          this.geoGraphicalDetails = data.geographical_details
            ? data.geographical_details
            : {};
          this.requestDetails = data.request_details
            ? data.request_details
            : {};
          this.documentOCR = data.document_ocr_results
            ? data.document_ocr_results
            : {};
          this.service = this.verificationDetails.service
            ? this.verificationDetails.service
            : "";
          this.continent = this.geoGraphicalDetails.continent;
          this.continent_code = this.geoGraphicalDetails.continent_code;
          this.country_code = this.geoGraphicalDetails.country_code;
          this.country_name = this.geoGraphicalDetails.country_name;
          this.host = this.geoGraphicalDetails.host;
          this.isp = this.geoGraphicalDetails.isp;
          this.latitude = this.geoGraphicalDetails.latitude;
          this.longitude = this.geoGraphicalDetails.longitude;
          this.rdns = this.geoGraphicalDetails.rdns;
          this.videos = data.video_proof;
          this.images = data.images_proof;
          this.similarityScore = Math.round(
            this.verificationDetails.similarity_score * 100
          );
          // matched faces
          this.face_match = data.matched_faces;
        })
        .catch((error) => {
          this.firstLoad = false;
          showErrorMessage(error);
          if (error.response.status === 401) {
            this.$store.dispatch("logout");
            this.$store.dispatch("SET_LOGOUT");
            this.$router.push("/");
          }
        });
    },

    /**
     * Opens the transaction history modal and passes the reference number for fetching transaction history.
     */
    openTransactionHistoryModal() {
      this.$refs.ViewInformation.transactionHistory(
        this.verificationDetails.reference_no
      );
    },
  },
  mounted() {
    this.getTransactionDetail();
  },
};
</script>

<style scoped>
.ocr-result-card {
  max-height: 450px;
  overflow: scroll;
  border-radius: 10px;
  background: #f7f8f9;
  box-shadow: 0px 1.4px 13px 0px rgba(0, 0, 0, 0.09),
    0px 7px 80px 0px rgba(0, 0, 0, 0.05) !important;
}
.image-text {
  padding-top: 10px;
  margin: 0;
  display: flex;
  justify-content: space-around;
  position: relative;
}
</style>
<style>
.modal-img-report .v-image__image--cover {
  background-size: contain;
}

.modal-img-report .v-carousel__item {
  background: #e9e9e9;
}

.divider-col {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
}

.details-custom:not(:last-child) {
  border-right: 1px solid #ebedf4;
  margin-right: 20px;
}

.browser-history {
  padding: 0px !important;
  padding-left: 28px !important;
}

.card-video {
  /* border-radius: 15px; */
  width: 450px;
  height: 280px;
}

.image-contents {
  overflow-x: auto;
  white-space: nowrap;
}

.title-text {
  color: #292b30;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.browser-content {
  color: #909295;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.sub-title-text {
  color: #292b30;
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  word-break: break-word;
}

.verification-title {
  /* padding: 8px 0px 0px 16px !important; */
}
.deleted-transaction {
  font-size: 20px !important;
  font-weight: 700;
  color: red !important;
}
.show-time-created {
  color: #0a2540 !important;
  font-family: Inter !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  width: 77px;
}
.show-day-created {
  color: #979797 !important;
  font-family: Inter !important;
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.status-pending-item {
  font-size: 12px !important;
  font-weight: 500;
  color: #c8a135 !important;
}

.status-accepted-item {
  font-size: 12px !important;
  font-weight: 500;
  color: #4fb0b9 !important;
}

.status-declined-item {
  font-size: 12px !important;
  font-weight: 500;
  color: #ff784b !important;
}

.pointer {
  cursor: pointer;
}

.Custom-btn-1 {
  display: none;
}

.status-in-process-item {
  font-size: 12px !important;
  font-weight: 500;
  color: #5ba9fa !important;
}

.status-initiated-item {
  font-size: 12px !important;
  font-weight: 500;
  color: #8b7dde !important;
}

.status-timout-item {
  font-size: 12px !important;
  font-weight: 500;
  color: #8990a2 !important;
}

.view-history-btn {
  background: #ebf4f4;
}

.v-responsive__content {
  width: auto !important;
}

.video-container {
  max-width: 100% !important;
}

/* .two-images-per-row {
    flex-basis: 50%; 
    margin-right: 8px; 
  } */
.pb-custom {
  padding-bottom: 50px !important;
}

.proof-img {
  border-radius: 8px;
}

@media only screen and (max-width: 600px) {
  .card-video {
    width: 100%;
    /* Set video width to 100% on smaller screens */
    height: auto;
    /* Allow the height to adjust based on the aspect ratio */
  }
}

@media only screen and (max-width: 960px) {
  .divider-col {
    display: none;
    /* Hide vertical dividers on smaller screens */
  }
}

@media only screen and (max-width: 1024px) {
  .card-video {
    max-width: 100%;
    /* Allow the video to adjust its width on screens up to 1024px */
    height: auto;
    /* Allow the height to adjust based on the aspect ratio */
  }
}

@media only screen and (max-width: 600px) {
  .divider-col {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
  }

  .divider-row {
    margin: 10px 0px;
  }
}

@media only screen and (max-width: 945px) {
  .divider-row {
    display: none;
  }
}

.image-container {
  min-width: 100% !important;
  /* height: 248px; */
  border-radius: 6px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.image-resize-set {
  width: 248px;
  height: 248px;
  object-fit: contain;
}
.tooltip-text-style {
  color: #e9e9e9 !important;
  font-family: Inter !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: normal !important;
}
.similarity-score {
  max-width: 120px;
  padding: 5px;
  text-align: center;
  border-radius: 10px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.set-progress-linear-width {
  width: 70%;
}
.similarity-score-grey {
  color: #8c8b8b !important;
}
.similarity-score-white {
  color: #e9e9e9 !important;
}
.similarity-score-black {
  color: #000000 !important;
}
</style>
